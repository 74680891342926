import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./revokeForm.css";

import httpService from "./../../services/httpService";
import { setUserJWT, getCurrentUser } from "./../../services/authService";

import { Formik } from "formik";
import * as Yup from "yup";
import NavBar from "../../components/common/navBar/navBar";
import axios from "axios";
import LandingNavBar from "../../components/home/navbar";

function RevokeForm() {
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  const [RecaptchaToken, setRecaptchaToken] = useState("");
  const [revokeMsg, setRevokeMsg] = useState("");
  const [mailtoAddress, setMailtoAddress] = useState("");

  useEffect(() => {
    const user = getCurrentUser();
    console.log("useer", user);
    setUser(user);
    
    if (user) {
      if (user.region === "egypt") {
        setMailtoAddress("VOISEGPrivacyOfficers@vodafone.com");
      } else if (user.region === "romania") {
        setMailtoAddress("privacy_vssromania@vodafone.com");
      } else if (user.region === "india") {
        setMailtoAddress("voisemployeeexperience@vodafone.com");
      }
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
  }, []);
  const history = useNavigate();
  const revoke = (user) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}user/revokerequest`, {
        email: user.email,
      })
      .then((res) => setRevokeMsg("Request to revoke submitted successfully"))
      .catch((res) => setRevokeMsg("Something Went Wrong"));
  };

  function SubmitRecaptcha(value) {
    setRecaptchaToken(value);
  }

  const revokeSchema = Yup.object().shape({
    email: Yup.string()
      .min(5, "Minimum 5 characters")
      .max(255, "Maximum 5 characters")
      .required("Email is required")
      .email("Invalid email")
      .matches(
        "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
        "Come back to revoke as a Vodafone"
      ),
  });

  return (
    <div className='revoke-form-container container-fluid'>
      <LandingNavBar></LandingNavBar>
      <div className='row d-flex justify-content-center mt-5 '>
        <div className='col-lg-12 col-md-12 col-xs-10 col-sm-10'>
          <span class='underline'>
            {" "}
            <p className='revoke-form-title mt-3 '>REVOKE</p>
          </span>
        </div>
        <div className='revoke-form-input-text form-group mt-4'>
          {mailtoAddress ? (
            <a href={`mailto:${mailtoAddress}`} className="revoke-link">
              Click here to send an email to revoke your account
            </a>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        {/* {serverErrorMessage ? (
          <div
            class='alert alert-danger revoke-form-alert-customize'
            role='alert'
          >
            {serverErrorMessage}
          </div>
        ) : null}
        <div className='col-lg-5 col-md-5 col-xs-10 col-sm-10 mt-2'>
          <Formik
            initialValues={{
              email: "",
              isChecked: false,
            }}
            validationSchema={revokeSchema}
            onSubmit={({ email }) => {}}
          >
            {({
              handleSubmit,
              getFieldProps,
              touched,
              errors,
              handleChange,
              values,
            }) => (
              <>
                {" "}
                <button
                  id='calculateError'
                  style={{ display: "none" }}
                  data-toggle='modal'
                  data-target='#ErrorCalculate'
                >
                  Add Group
                </button>
                <div
                  className='modal fade'
                  id='ErrorCalculate'
                  tabindex='-1'
                  role='dialog'
                  aria-labelledby='addGroup'
                  aria-hidden='true'
                  data-backdrop='static'
                >
                  <div className='modal-dialog modal-md' role='document'>
                    <div className='modal-content add-groups-modal-body'>
                      <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                          Delete Request Confirmation
                        </h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div className='modal-body row'>
                        <p className='modal-title p-5'>
                          <p className='modal-question'>
                            Are You Sure You Want To Delete Your Submission ?
                          </p>
                          <br />
                          <p className='revoke-form-text'>
                            By submitting the delete request, your project
                            details and all team members data will be{" "}
                            <p className='revoke-form-urgent-text'>
                              Deleted in 48 hours .
                            </p>
                          </p>
                          <br />
                          <p className='revoke-form-text'>
                            {" "}
                            You will not be able to participate in _VOIS
                            International Hackathon.
                          </p>
                        </p>
                      </div>
                      <div
                        className='modal-footer'
                        style={{ justifyContent: "center" }}
                      >
                        <button
                          type='button'
                          className='revoke-submitbtn'
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={() => {
                            const user = { email: values.email };
                            revoke(user);
                          }}
                        >
                          Yes, I'm sure. Delete my submission.
                        </button>
                        <button
                          type='button'
                          className='revoke-cancelbtn'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          No, I changed my mind. Keep my submission.
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <div className='revoke-form-input-text form-group'>
                      <p htmlFor='email'>Please enter your email</p>
                      <input
                        type='email'
                        className='revoke-form-input form-control'
                        id='email'
                        name='email'
                        {...getFieldProps("email")}
                      />
                      {touched.email && errors.email ? (
                        <div className='alert alert-danger reg-alert'>
                          {errors.email}
                        </div>
                      ) : null}
                      {revokeMsg != "" ? (
                        <div className='alert alert-danger reg-alert'>
                          {revokeMsg}
                        </div>
                      ) : null}
                    </div>

                    <div className='revoke-form-input-text col-lg-5 col-md-6 col-xs-5 col-sm-5 ml-auto mr-auto'>
                      {RecaptchaToken !== "" ? (
                        <button
                          type='button'
                          className='revoke-form-actionBtn'
                          onClick={() => {
                            document.getElementById("calculateError").click();
                          }}
                        >
                          <span>Revoke</span>
                        </button>
                      ) : (
                        <span className='d-flex justify-content-center col-2  ml-auto mr-auto '>
                          <ReCAPTCHA
                            sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                            onChange={SubmitRecaptcha}
                            theme='dark'
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div> */}
      </div>
    </div>
  );
}

export default RevokeForm;

{
  /* <NavBar user={user} navColor={navColor} setNavColor={setNavColor} />
      <div className=' revoke-form-pageContainer'>
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'></div>
          <div className='revoke-form-container col-lg-4 col-md-4 col-sm-4 col-xs-4'>
            <div className='revoke-body-title'> _VOIS Global Hackathon</div>
            <div className='revoke-form-body-subtitle'>Revoke </div>
            {serverErrorMessage ? (
              <div
                class='alert alert-danger revoke-form-alert-customize'
                role='alert'
              >
                {serverErrorMessage}
              </div>
            ) : null}
            <div>
              <Formik
                initialValues={{
                  email: "",
                  isChecked: false,
                }}
                validationSchema={revokeSchema}
                onSubmit={({ email }) => {
                  const user = { email };
                  revoke(user);
                }}
              >
                {({
                  handleSubmit,
                  getFieldProps,
                  touched,
                  errors,
                  handleChange,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                      <div className='revoke-form-input-text form-group'>
                        <p htmlFor='email'>Email</p>
                        <input
                          type='email'
                          className='revoke-form-input form-control'
                          id='email'
                          name='email'
                          {...getFieldProps("email")}
                        />
                        {touched.email && errors.email ? (
                          <div className='alert alert-danger revoke-form-alert-customize'>
                            {errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className='revoke-form-input-text'>
                        {RecaptchaToken !== "" ? (
                          <button
                            type='submit'
                            className='revoke-form-actionBtn'
                          >
                            <span>Revoke</span>
                          </button>
                        ) : (
                          <span className='d-flex justify-content-center align-items-center'>
                            <ReCAPTCHA
                              sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                              onChange={SubmitRecaptcha}
                              theme='dark'
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'></div>
        </div>
      </div> */
}
